// External Library/Component Imports
import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useParams,
} from "react-router-dom";

// Local Component imports
import Home from "./pages/Home";
import Landing from "./pages/Landing";

// Local media imports
import "./App.scss";

// Default class export
export default class App extends Component {
  constructor() {
    super();

    this.state = {};
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route path="/" component={() => <Landing />} />
        </Switch>
      </Router>
    );
  }
}
