import React, { Component } from "react";

import logo from "../media/full-logo.svg";

class Landing extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <div className="full-landing">
        <div className="container">
          <div className="landing-contents w-100">
            <img src={logo} className="landing-logo" />

            <div className="w-100 mt-5">
              <a
                target="_blank"
                href="mailto:techinterlockco@gmail.com"
                className="btn py-3 px-5 transition-all  font-white font-hind font-600 white-outline"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Landing;
