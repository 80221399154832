import React, { Component } from "react";
import { Fade } from "react-reveal";

import { Link, animateScroll as scroll } from "react-scroll";

import logo from "../media/just-logo.svg";
import down from "../media/angle-arrow-down.svg";

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navStatus: false,
      navExpand: false,
    };

    this.noteNav = this.noteNav.bind(this);
    this.expandNav = this.expandNav.bind(this);
  }

  noteNav() {
    if (window.pageYOffset > 150) {
      this.setState({ navStatus: true });
    } else {
      this.setState({ navStatus: false });
    }
  }

  expandNav() {
    this.setState({ navExpand: !this.state.navExpand });
  }

  componentDidMount() {
    window.addEventListener("scroll", this.noteNav);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.noteNav);
  }

  render() {
    return (
      <Fade top>
        <div
          className={`navbar-full transition-all py-3 ${
            this.state.navStatus ? "navbar-black" : "navbar-transparent"
          }`}
        >
          <div className="container">
            <div className="row">
              <Link
                spy={true}
                smooth={true}
                duration={750}
                className={`col logo-box`}
                to="home"
              >
                <img
                  src={logo}
                  className="transition-all img-logo turn-white"
                />
              </Link>
              <div
                onClick={(e) => {
                  e.preventDefault();
                  this.expandNav();
                }}
                className="col-auto m-display"
              >
                <img
                  className={`expand-arrow transition-all ${
                    this.state.navExpand ? "expanded-nav" : ""
                  }`}
                  src={down}
                />
              </div>
              <div className="col-auto d-display">
                <div className="link-shell row">
                  <Link
                    spy={true}
                    smooth={true}
                    duration={750}
                    activeClass="active"
                    className="clickable transition-all nav-link col-auto font-caps"
                    to="about"
                  >
                    About
                  </Link>
                  <Link
                    spy={true}
                    smooth={true}
                    duration={750}
                    activeClass="active"
                    className="clickable transition-all nav-link col-auto font-caps"
                    to="contact"
                  >
                    Contact
                  </Link>
                </div>
              </div>
            </div>
            <div
              className={`w-100 m-display link-shell transition-all ${
                this.state.navExpand ? "expanded-nav" : ""
              }`}
            >
              <Link
                spy={true}
                smooth={true}
                duration={750}
                activeClass="active"
                className="clickable transition-all nav-link w-100 font-caps"
                to="about"
              >
                About
              </Link>
              <Link
                spy={true}
                smooth={true}
                duration={750}
                activeClass="active"
                className="clickable transition-all nav-link w-100 font-caps"
                to="contact"
              >
                Contact
              </Link>
            </div>
          </div>
        </div>
      </Fade>
    );
  }
}

export default Navbar;
