import React, { Component } from "react";
import { Fade } from "react-reveal";
import Typist from "react-typist";
import TypistLoop from "react-typist-loop";

import * as emailjs from "emailjs-com";

import { scroller } from "react-scroll";

import Navbar from "../components/Navbar";

import background from "../media/intro-bg.jpg";
import dots from "../media/dots.svg";
import downDown from "../media/downdown.svg";
import checkMark from "../media/check.svg";

import aboutDesktop from "../media/desktop-about.jpg";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactName: "",
      contactCompany: "",
      contactPhone: "",
      contactEmail: "",
      contactMessage: "",
      contactSheet: false,
      contactSubmit: false,
      submitError: "",
      clickSubmit: false,
    };

    this.handleName = this.handleName.bind(this);
    this.handleCompany = this.handleCompany.bind(this);
    this.handlePhone = this.handlePhone.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
    this.handleSheet = this.handleSheet.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.scrollContact = this.scrollContact.bind(this);
    this.scrollAbout = this.scrollAbout.bind(this);
  }

  scrollContact() {
    scroller.scrollTo("contact", {
      duration: 750,
      smooth: true,
      offset: 80, // Scrolls to element + 50 pixels down the page
    });
  }

  scrollAbout() {
    scroller.scrollTo("about", {
      duration: 750,
      smooth: true,
      offset: 80, // Scrolls to element + 50 pixels down the page
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    if (!this.state.clickSubmit) {
      this.setState({ clickSubmit: true });

      if (
        this.state.contactName === "" ||
        this.state.contactCompany === "" ||
        this.state.contactPhone === "" ||
        this.state.contactMessage === "" ||
        this.state.contactEmail === "" ||
        !this.state.contactEmail.includes("@")
      ) {
        this.setState({
          clickSubmit: false,
          submitError: "Please properly fill all entries and try again.",
        });
      } else {
        emailjs.send(
          "service_r9o5lde",
          "template_1vso881",
          {
            from_name: this.state.contactEmail,
            full_name: this.state.contactName,
            company_name: this.state.contactCompany,
            phone_no: this.state.contactPhone,
            to_name: "techinterlockco@gmail.com",
            subject: "New Message Interlock",
            message_html: this.state.contactMessage,
          },
          "user_nydkarXR1wsTdD52fmdsL"
        );

        this.setState({
          contactSubmit: true,
          contactName: "",
          contactCompany: "",
          contactPhone: "",
          contactEmail: "",
          contactMessage: "",
          contactSheet: false,
          submitError: "",
          clickSubmit: false,
        });
      }
    }
  }

  handleSheet(e) {
    if (!this.state.contactSubmit) {
      this.setState({ contactSheet: e.target.value });
    }
  }

  handleName(e) {
    e.preventDefault();
    if (!this.state.contactSubmit) {
      this.setState({ contactName: e.target.value });
    }
  }

  handleCompany(e) {
    e.preventDefault();
    if (!this.state.contactSubmit) {
      this.setState({ contactCompany: e.target.value });
    }
  }

  handlePhone(e) {
    e.preventDefault();
    if (!this.state.contactSubmit) {
      this.setState({ contactPhone: e.target.value });
    }
  }

  handleEmail(e) {
    e.preventDefault();
    if (!this.state.contactSubmit) {
      this.setState({ contactEmail: e.target.value });
    }
  }

  handleMessage(e) {
    e.preventDefault();
    if (!this.state.contactSubmit) {
      this.setState({ contactMessage: e.target.value });
    }
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <div className="full-home page-container">
        <Navbar />
        <section id="home" className="intro">
          <img className="abs-dots one-1 d-display" src={dots} />
          <img className="abs-dots one-2 d-display" src={dots} />
          <div className="m-display w-100">
            <div className="container-mobile pt-5 m-display">
              <h1 className="font-white font-oswald font-500">
                <span className="bg-black p-0 pr-md-5 pt-0">Interlock</span>
                <br />
                <span className="bg-black p-0 pr-md-4 pb-3 pos-abs">
                  relationships
                </span>
                <br />
                <span className="bg-black p-0 pos-abs pb-3">
                  to unlock{" "}
                  <TypistLoop interval={500}>
                    {["value", "power", "speed"].map((text, key) => (
                      <Typist
                        className="typist-inline transition-all p-0 pr-md-3 font-700"
                        key={text}
                        startDelay={500}
                        cursor={{
                          element: "",
                        }}
                      >
                        {text}
                        <Typist.Backspace count={6} delay={2000} />
                      </Typist>
                    ))}
                  </TypistLoop>
                </span>
                <br />
              </h1>
            </div>

            <div className="container py-5 m-display">
              <a
                onClick={this.scrollContact}
                className="btn py-3 w-50 transition-all font-white font-hind font-600 white-outline"
              >
                GET IN TOUCH
              </a>
              <a
                onClick={this.scrollAbout}
                className="btn py-3 w-50 transition-all font-white font-hind font-600 white-outline no-outline-left"
              >
                GET PREPARED
              </a>
            </div>
          </div>

          <div className="container d-display">
            <div className="row">
              <div className="col-md-auto">
                <h1 className="font-white font-oswald font-500">
                  <span className="bg-black p-0 pr-md-5 pt-0">Interlock</span>
                  <br />
                  <span className="bg-black p-0 pr-md-4 pb-3 pos-abs">
                    relationships
                  </span>
                  <br />
                  <span className="bg-black p-0 pos-abs mt-3 pb-3">
                    to unlock{" "}
                    <TypistLoop interval={500}>
                      {["value", "power", "speed"].map((text, key) => (
                        <Typist
                          className="typist-inline transition-all p-0 pr-md-3 font-700"
                          key={text}
                          startDelay={500}
                          cursor={{
                            element: "",
                          }}
                        >
                          {text}
                          <Typist.Backspace count={6} delay={2000} />
                        </Typist>
                      ))}
                    </TypistLoop>
                  </span>
                  <br />
                </h1>
                <Fade bottom>
                  <div className="btn-container w-100 p-0 m-0 pr-md-4 mt-5 pt-md-5">
                    <a
                      onClick={this.scrollContact}
                      className="btn py-3 transition-all w-100  font-white font-hind font-600 white-outline"
                    >
                      GET IN TOUCH
                    </a>
                    <a
                      onClick={this.scrollAbout}
                      className="btn py-3 w-100 transition-all font-white mt-4 font-hind font-600 white-outline"
                    >
                      GET PREPARED
                    </a>
                  </div>
                </Fade>
              </div>
              <div className="col-md">
                <Fade right>
                  <img
                    className="w-100 d-display transition-all"
                    src={background}
                  />
                </Fade>
              </div>
            </div>
          </div>
        </section>
        <section id="about" className="about mt-5">
          <div className="container mt-5">
            <Fade left>
              <div className="section-header w-100 mb-5">
                <h1 className="font-oswald font-white">About Us</h1>
                <h1 className="font-oswald font-white outline-it">About Us</h1>
              </div>
            </Fade>
            <div className="w-100 m-display">
              <div className="paragraph-container">
                <Fade right>
                  <div className="paragraph-bar">
                    <p className="w-100 font-white font-hind p-4">
                      Founded by Rich Mazza in 2013 Interlock Co is an
                      international consulting firm that helps grow, develop,
                      and transition companies for tomorrow. Through
                      relationships with developers, strategic partners,
                      operators, and capital providers we aim to add value to
                      existing and new companies.
                      <br />
                      <br />
                      The Interlock Co. proprietary platform is specifically
                      engineered to mitigate risks and exposures utilizing
                      securitization techniques to raise the lowest-cost,
                      non-recourse, capital for clients and partners. Bespoke
                      offerings for our sponsors and investors.
                    </p>
                  </div>
                </Fade>
                <Fade right>
                  <div className="paragraph-bar w-100 two-bar">
                    <div className="down-arrow-bar">
                      <img className="transition-all" src={downDown} />
                    </div>
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        this.scrollContact();
                      }}
                      className="transition-all get-started-btn font-hind font-700 text-center p-3"
                    >
                      GET STARTED
                    </div>
                  </div>
                </Fade>
              </div>
              <Fade left>
                <p className="w-100 font-white font-hind mt-5">
                  We are<span className="highlight-this"> seasoned </span>in
                  banking, mergers and acquisitions, leverage buyouts, trade
                  finance and insurance. Our project history spans energy,
                  technology, real estate, telecommunications, agriculture and
                  international infrastructure projects. From startups to
                  Fortune 100 companies, we have added
                  <span className="highlight-this"> value </span>through
                  structuring, executing, and creating transactions for our
                  clients.
                  <br />
                  <br />
                  Through strategic relationships with domestic and
                  international best-in-class compliance officers, law firms,
                  banks, financial institutions and insurance companies we have
                  developed an
                  <span className="highlight-this">
                    {" "}
                    institutional and retail platform{" "}
                  </span>
                  to benefit our clients.
                  <br />
                  <br />
                  <span className="highlight-this">
                    We focus on capital raising, debt service, commodities
                    transactions, and risk mitigation.
                  </span>
                </p>
              </Fade>
            </div>
            <div className="row w-100 d-display">
              <Fade left>
                <div className="col-md left-col">
                  <img
                    className="about-pic d-display mt-5"
                    src={aboutDesktop}
                  />
                </div>
              </Fade>
              <Fade right cascade>
                <div className="col-md p-col pr-0 pr-md-5">
                  <div className="p-div-blur p-0 m-0 p-md-4 mr-md-5">
                    <p className="w-100 font-white py-4 p-md-0 m-0 font-hind">
                      Founded by Rich Mazza in 2013 Interlock Co is an
                      international consulting firm that helps grow, develop,
                      and transition companies for tomorrow. Through
                      relationships with developers, strategic partners,
                      operators, and capital providers we aim to add value to
                      existing and new companies.
                      <br />
                      <br />
                      The Interlock Co. proprietary platform is specifically
                      engineered to mitigate risks and exposures utilizing
                      securitization techniques to raise the lowest-cost,
                      non-recourse, capital for clients and partners. Bespoke
                      offerings for our sponsors and investors.
                    </p>

                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        this.scrollContact();
                      }}
                      className="get-started font-black font-hind transition-all font-600 p-4"
                    >
                      <div className="transition-all">GET STARTED</div>
                      <img className="transition-all" src={downDown} />
                    </a>
                  </div>
                </div>
              </Fade>
            </div>
            <Fade right>
              <div className="w-100 d-display other-paragraphs">
                <div className="blurry-div row">
                  <p className="w-100 font-white font-hind p-3 col">
                    We are seasoned in banking, mergers and acquisitions,
                    leverage buyouts, trade finance and insurance. Our project
                    history spans energy, technology, real estate,
                    telecommunications, agriculture and international
                    infrastructure projects. From startups to Fortune 100
                    companies, we have added
                    <span className="highlight-this"> value </span>through
                    structuring, executing, and creating transactions for our
                    clients.
                  </p>
                  <p className="w-100 font-white font-hind p-3 col">
                    Through strategic relationships with domestic and
                    international best-in-class compliance officers, law firms,
                    banks, financial institutions and insurance companies we
                    have developed an institutional and retail platform to
                    benefit our clients.
                    <br />
                    <br />
                    <span className="highlight-this font-600">
                      We focus on capital raising, debt service, commodities
                      transactions, and risk mitigation.
                    </span>
                  </p>
                </div>
              </div>
            </Fade>
          </div>
        </section>
        <section id="contact" className="contact mt-5 py-5">
          <div className="container">
            <div className="row">
              <Fade left>
                <div className="col-md-auto section-header">
                  <h1 className="font-oswald font-white">
                    <span className="greyed">Contact Us</span> <br />
                    Reach Out <br />
                    <span className="greyed">Send a Message</span>
                  </h1>
                  <img className="contact-dots d-display" src={dots} />
                </div>
              </Fade>
              <div className="col-md form-box">
                <h1 className="font-oswald font-white d-display opacity-none">
                  <span className="greyed">Contact Us</span> <br />
                </h1>
                <form onSubmit={this.handleSubmit}>
                  <div className="row">
                    <Fade bottom>
                      <div className="col-md">
                        <h3 className="input-label">Name</h3>
                        <input
                          className={`w-100 ${
                            this.state.contactSubmit ? "color-up" : ""
                          }`}
                          type="text"
                          name="name"
                          value={this.state.contactName}
                          onChange={this.handleName}
                        />
                      </div>
                    </Fade>
                    <Fade bottom>
                      <div className="col-md">
                        <h3 className="input-label">Company</h3>
                        <input
                          className={`w-100 ${
                            this.state.contactSubmit ? "color-up" : ""
                          }`}
                          type="text"
                          name="name"
                          value={this.state.contactCompany}
                          onChange={this.handleCompany}
                        />
                      </div>
                    </Fade>
                  </div>

                  <div className="row">
                    <Fade bottom>
                      <div className="col-md">
                        <h3 className="input-label">Preferred Phone #</h3>
                        <input
                          className={`w-100 ${
                            this.state.contactSubmit ? "color-up" : ""
                          }`}
                          type="text"
                          name="name"
                          value={this.state.contactPhone}
                          onChange={this.handlePhone}
                        />
                      </div>
                    </Fade>
                    <Fade bottom>
                      <div className="col-md">
                        <h3 className="input-label">Email Address</h3>
                        <input
                          className={`w-100 ${
                            this.state.contactSubmit ? "color-up" : ""
                          }`}
                          type="text"
                          name="name"
                          value={this.state.contactEmail}
                          onChange={this.handleEmail}
                        />
                      </div>
                    </Fade>
                  </div>

                  <Fade bottom>
                    <div className="w-100 message-box">
                      <a
                        className={`error-highlight font-hind font-600 p-2 ${
                          this.state.submitError === "" ||
                          this.state.clickSubmit
                            ? "display-none"
                            : ""
                        }`}
                      >
                        {this.state.submitError}
                      </a>
                      <h3 className="input-label">Message</h3>
                      <textarea
                        className={`w-100 ${
                          this.state.contactSubmit ? "color-up" : ""
                        }`}
                        value={
                          this.state.contactSubmit
                            ? ""
                            : this.state.contactMessage
                        }
                        onChange={this.handleMessage}
                      />
                      <button
                        className={`row transition-all ${
                          this.state.contactSubmit ? "submitted" : ""
                        }`}
                        onClick={this.handleSubmit}
                      >
                        {this.state.contactSubmit ? "SUBMITTED" : "SUBMIT"}
                      </button>
                    </div>
                  </Fade>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Home;
